.opinion-container{
    height: 500px;
    width: 100%;
    margin: 0 auto;
    background-color: black;
}
.opinion-sections{
    margin: 0 auto;
    width: 90%;
    position: relative;
}
.title-opinion{
    height: 80px;
    padding-top: 50px;
    font-size: 50px;
    text-align: center;
    width: 100%;
    color: #c93f8f;
    font-family: charcoal-Dance, sans-serif;
}
.op-conatiner{
    height: 350px;
    display: grid;
    font-family: sans-serif;
    grid-template-columns: 33% 33% 33%;
    grid-template-areas:"a b c";
    grid-gap: 10px;
}
.op-conatiner .op-item1{
    grid-area: a;
    width: 100%;
    height: 97%;
    text-align: center;
    overflow: hidden;
}
.op-conatiner .op-item2{
    grid-area: b;
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: hidden;
    box-shadow: -50px 0 30px -50px rgba(255, 153, 204, 0.5), 50px 0 30px -50px rgba(255, 153, 204, 0.5);
}


.op-conatiner .op-item3{
    grid-area: c;
    width: 100%;
    height: 97%;
    text-align: center;
    overflow: hidden;
}
.img-item{
    width: 30%;
    margin: 0 auto;
    filter: invert(100%);
}
.p-item{
    padding-left: 15px;
    padding-right: 15px;
    font-size: 20px;
    color: white;
    text-align: center;
}



.container-user-info{
    width: 100%;
    margin: 0 auto;
    text-align: center;
    color: aliceblue;
}
.container-user-info img{
    padding-top: 20px;
    width: 40%;
    border-radius: 50%;
}
