.slider{
    height: 92vh;
    overflow: hidden;
    background-color: black;
    position: relative;
}
.previmg{
  position: absolute;
  z-index: 200;
  top: 40%;
  right: 0;
  width: 40px;
  background-color: rgba(161, 161, 161, 0.229);
}
.previmg svg{
  color: white;
  font-size: 50px;
}
.previmg svg:hover{
  cursor: pointer;
}

.neximg{
  position: absolute;
  z-index: 200;
  top: 40%;
  width: 40px;
  background-color: rgba(161, 161, 161, 0.229);
}
.neximg svg{
  color: white;
  font-size: 50px;
}

.slider-carrusel{
    position: relative;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease, transform 0.5s ease;
    background-color: black;
}
.slider-carrusel img{
    width: 100%;
    margin-top: -150px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    transition: opacity 0.5s ease, transform 0.5s ease;
}
.slider-carrusel img.next {
  opacity: 0;
  transform: scale(1.1);
}

.contenido-slider{
   position: absolute;
   left: 0; 
   top: 0;
   width: 40%;
   text-align: justify;
   margin-top: 10%;
   margin-left: 20px;
   font-family: Tusker-Grotesk-6700Bold;
}
.contenido-slider p{
    color:white;
    font-size: 30px;
}

.transition {
    animation-name: slideTransition;
    animation-duration: 3s;
  }
  
  @keyframes slideTransition {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .barra-carga{
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
    height: 10px;
    background-color: rgb(81, 10, 66);
    transition: width 0.5s ease;
  }

  @media only screen and (max-width: 1080px) {
    .slider{
      height: 500px;
      overflow: hidden;
    }
    .slider-carrusel img{
      margin-top: 0px;
    }
  }

  @media only screen and (max-width: 720px) {
    .slider{
      height: 300px;
      overflow: hidden;
    }
    .slider-carrusel img{
      margin-top: 0px;
    }
  }

  @media only screen and (max-width: 480px) {
    .slider{
      height: 150px;
      overflow: hidden;
    }
    .slider-carrusel img{
      margin-top: 0px;
    }
  }

  .botones-slider{
    background-color: red;
    position: absolute;
    width: 50px;
    margin-top: -120px;
  }