.products_container{
    height: 100vh;
    background-image: url("../imgcomponents/meta.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 100%;
    position: relative;
    overflow: hidden;
}
.meta{
    width: 90%;
    margin: 0 auto;
}
.meta .title-meta{
    width: 80%;
    padding-top: 90px;
    font-size: 85px;
    font-family: Tusker-Grotesk-6700Bold, sans-serif;
    color: white;
}
.meta .text-meta{
    padding-top: 60px;
    width: 70%;
    font-size: 45px;
    font-family:  Tusker-Grotesk-6700Bold, sans-serif;
    color: white;
}

@media only screen and (max-width: 720px) {
   .meta .title-meta{
    font-size: 50px;
    width: 95%;
    text-align: center;
   }
   .meta .text-meta{
    font-size: 30px;
    width: 95%;
    text-align: center;
    }
}