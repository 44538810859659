

.home-request{
    width: 100%;
    background-color: #1c1c1c;
    height: 100vh;
}
.request-year{
    margin: 0 auto;
    width: 90%;
    text-align: center;
    padding-top: 150px;
}
.request-logo img{
    width: 200px;
}

.request-p{
    /* color: white; */
    font-size: 70px;
    font-family: charcoal-Dance;
    padding-bottom: 20px;
    
}
.span1{
    background: linear-gradient(to right, #e2de1d 40%, #FFFFFF 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.span2{
    background: linear-gradient(to right,#FFFFFF 25%, #c93f8f 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
}
.button-seccion{
  display: flex;
  justify-content: center;
}
.request-button1,
.request-button2{
    width: 130px;
    height: 50px;
    border-radius: 10px;
    font-size: 30px;
    margin: 0 20px;
}
.request-button1:hover{
    background-color: #e2de1d;
    cursor: pointer;
}
.request-button2:hover{
    background-color: #c93f8f;
    cursor: pointer;
}

.alert-edad{
    margin: 0 auto;
    width: 50%;
    padding-top: 35px;
}
.p-alert{
    font-size: 45px;
    font-family: charcoal-Dance;
    color: #c93f8f
}



.pruebas{
    height: 1500px;
}