.history-container{
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding-bottom: 20px;
    /* background-color: #F8EEE3; */
    background-color: #1e1002;  
}
.history-seccions{
    margin: 0 auto;
    width: 90%;
    height: 100%;
    display: grid;
    color: white;
    grid-template-columns: 40% 60%;
    grid-template-areas:"a b";
    grid-gap: 20px;
}
.his1{
    grid-area: a;
    width: 100%;
    height: 100%;
}
.img-history{
    width: 100%;
    margin-top: 40px;
    text-align: center;
    height: 90%;
    overflow: hidden;
}
.img-history img{
    height: 100%;
    box-shadow: 0px 80px 80px -50px rgba(194, 194, 194, 0.5), 50px 80px 30px -50px rgba(35, 35, 35, 0.5);
}

.his2{
    grid-area: b;
    width: 100%;
    height: 100%;
    text-align: center;
}
.title-history{
    margin-top: 50px;
}
.title-history p{
    font-family: Tusker-Grotesk-6700Bold, sans-serif;
    font-size: 40px;
    color: antiquewhite;
}
.desc-history{
    width: 100%;
}
.desc-history .desc-company{
    font-family: Oswald-Light, sans-serif;
    font-size: 25px;
    text-align: justify;
    height: 80px;
    padding-bottom: 20px;
    padding-top: 10px;
    color: antiquewhite;
}
.desc-history .valores-title{
    width: 100%;
    font-weight: bold;
    font-size: 30px;
    height: 70px;
    text-align: left;
    font-family:  Oswald-Regular, sans-serif;
    color: antiquewhite;
}
.contenedor-valores{
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-areas:"a b c d";
    filter: sepia(38%) hue-rotate(10deg);
}
.valor1{
    grid-area: a;
    padding-bottom: 50px;
}
.valor2{
    grid-area: b;
    padding-top: 50px;
}
.valor3{
    grid-area: c;
    padding-bottom: 50px;
}
.valor4{
    grid-area: d;
    padding-top: 50px;
}
.corch-img{
    width: 85%;
    height: 85%;
}
.corch-img:hover{
    cursor: pointer;
    animation: balanceo 1s infinite;
    transform-origin: center;
}
.seleccionado-val{
    filter: brightness(80%);
    animation: none !important;
    transform-origin: center;
}


@keyframes balanceo {
    0% {
      transform: rotateZ(-10deg);
    }
    50% {
      transform: rotateZ(10deg);
    }
    100% {
      transform: rotateZ(-10deg);
    }
  }
.descval{
    padding-top: 10px;
    height: 100%;
    color: black;
    font-size: 24px;
    text-align: justify;
    font-family: Oswald-Light, sans-serif;
    overflow: auto;
    color: antiquewhite;
}


@media only screen and (max-width: 720px) {
    .history-seccions{
        grid-template-columns: 40% 60%;
        grid-template-areas:"b b";
        height: 100%;
    }
    .his1{
        display: none;
    }
}
