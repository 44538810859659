.navbar{
    background-color: black;
    margin: 0 auto;
    height: 60px;
}
.logo-nav{
    padding: 10px;
}

.logo-nav img{
    height: 50px;
    margin-top: -5px;
}
.content-navbar{
    margin: 0 auto;
    width: 95%;
    font-size: 30px;
    display: grid;
    color: white;
    font-family: charcoal-Dance, sans-serif;
    grid-template-columns: 40% 60%;
    grid-template-areas:"a b";
    grid-gap: 10px;
}
.logo-nav{
    grid-area: a;
}
.menu-nav{
    grid-area: b;
}
.menu-nav {
    display: flex;
    justify-content: center;
    padding: 20px;
}
.ul-items {
    display: flex;
    list-style-type: none;
    padding: 0;
}
.li-items {
    margin-right: 25px;
}
.li-items:last-child {
    margin-right: 0;
}
.li-items a {
    text-decoration: none;
    color: white;
}
.li-items a:hover{
    color: #ed0083;
    cursor: pointer;
}
@media only screen and (max-width: 720px) {
    .menu-nav{
        display: none;
    }
}