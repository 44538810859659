*{
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: Oswald-Bold;
  src: url("./font/Oswald/static/Oswald-Bold.ttf");
}
@font-face {
  font-family: Oswald-ExtraLight;
  src: url("./font/Oswald/static/Oswald-ExtraLight.ttf");
}
@font-face {
  font-family: Oswald-Light;
  src: url("./font/Oswald/static/Oswald-Light.ttf");
}
@font-face{
  font-family: Oswald-Medium;
  src: url("./font/Oswald/static/Oswald-Medium.ttf");
}
@font-face {
  font-family: Oswald-Regular;
  src: url("./font/Oswald/static/Oswald-Regular.ttf");
}
@font-face{
  font-family: Tusker-Grotesk-6700Bold;
  src: url("./font/Tusker-grotesk-font/TuskerGrotesk-6700Bold.ttf");
}
@font-face {
  font-family: charcoal-Dance;
  src: url("./font/charcoal-dance/CharcoalDance-Regular.otf");
}



body {
  font-family: MiFuente1, sans-serif;
}
