.contact-container{
    width: 100%;
}
.grid-contact{
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas:"a b";
    position: relative;
    height: 100vh;
}
.map{
    grid-area: a;
    width: 95%;
    height: 95%;
    margin: auto auto;
}
.form{
    grid-area: b;
    background-image: url("../imgcomponents/contacto.jpg");
    background-repeat: no-repeat;
    background-position-y: 70%;
    background-size: cover;
    margin: 0 auto;
    padding-top: 10%;
}
iframe{
    width: 100%;
    height: 100%;
}
.form-items{
    width: 100%;
    text-align: center;
}
.form-items h1{
    font-size: 60px;
    font-family: Oswald-Bold;
    padding-bottom: 20px;
    color: white;
}
.input1 input{
    font-size: 23px;
    width: 80%;
    height: 40px;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 10px;
    border: none;
    background-color: rgba(255, 255, 255, 0.7);
    outline: none;
}
.input1 input::placeholder{
    color: rgb(51, 28, 0);
}
.input1 textarea::placeholder{
    color: rgb(51, 28, 0);
}
.input1 textarea{
    outline: none;
    font-size: 24px;
    width: 80%;
    height: 150px;
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: rgba(255, 255, 255, 0.7); 
}
.send-email{
    margin-top: 20px;
    width: 50%;
    height: 50px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 15px;
    background-color: rgb(255, 255, 255);
}
.send-email:hover{
    cursor: pointer;
}


@media only screen and (max-width: 720px) {
    .grid-contact{
        grid-template-areas:
        "a a"
        "b b";
        height: 100%;
    }
.map{
    width: 100%;
    height: 400px;
}
.form{
    padding-bottom: 20px;
}
}