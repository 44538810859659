.productos{
    width: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;
}
.grid-prod{
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-areas:"a b c d e";
    position: relative;
}
.prod1{
    grid-area: a;
}
.prod2{
    grid-area: b;
}
.prod3{
    grid-area: c;
}
.prod4{
    grid-area: d;
}
.prod5{
    grid-area: e;
}

.prod1 img, .prod2 img, .prod3 img, .prod4 img, .prod5 img{
    width: 100%;
    margin-top: -100px;
}
.prod1 img:hover{
    filter: brightness(0.5); 
}
.prod2 img:hover{
    filter: brightness(0.5); 
}
.prod3 img:hover{
    filter: brightness(0.5); 
}
.prod4 img:hover{
    filter: brightness(0.5); 
}
.prod5 img:hover{
    filter: brightness(0.5); 
}


@media only screen and (max-width: 900px) {
    .prod1 img, .prod2 img, .prod3 img, .prod4 img, .prod5 img{
        height: 100vh;
        width: 150%;
    }
}

/* ------------------------------------------------------------------------------- */

.perfil-marca{
    width: 100%;
    background-color: black;
}
.grid-brand{
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas:"a b";
    position: relative;
}
.gb1{
    grid-area: a;
    position: relative;
}
.gb1 img{
    width: 100%;
    display: block;
    margin: auto;
    height: 70vh;
}
.gb1 .seccion1{
    position: absolute;
    top: 17%;
    right: 5%;
    width: 45%;
    text-align: right;
}
.gb1 .seccion1 .nosh2{
    font-size: 38px;
    font-family:  Tusker-Grotesk-6700Bold, sans-serif;
    color:  #c93f8f;
    padding-bottom: 20px;
    text-align: center;
}
.gb1 .seccion1 .nosp{
    font-size: 26px;
    font-family: Oswald-Light, sans-serif;
    text-align: right;
    color: white;
    padding-bottom: 40px;
}
.gb1 .button-info{
    background-color: #c93f8f;
    border-radius: 10px;
    font-size: 20px;
    border: none;
    width: 200px;
    height: 50px;
    color: white;
    font-weight: bold;
}
.gb1 .button-info:hover{
    cursor: pointer;
}
.gb2{
    grid-area: b;
    position: relative;
}
.gb2 img{
    width: 100%;
    display: block;
    margin: auto;
    height: 70vh;
}
.capa-pho2{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* Color oscuro con transparencia */ 
}

.gb2 .seccion2{
    position: absolute;
    top: 17%;
    left: 15%;
    width: 41%;
    text-align: center;
}
.gb2 .seccion2 .nosh2{
    font-size: 38px;
    font-family:  Tusker-Grotesk-6700Bold, sans-serif;
    color:  #e2de1d;
    padding-bottom: 20px;
    text-align: left;
}
.gb2 .seccion2 .nosp{
    font-size: 26px;
    font-family: Oswald-Light, sans-serif;
    text-align: left;
    color: white;
    padding-bottom: 50px;
}
.gb2 .button-info{
    background-color: #e2de1d;
    border-radius: 10px;
    font-size: 20px;
    border: none;
    width: 200px;
    height: 50px;
    color: black;
    font-weight: bold;
}
.gb2 .button-info:hover{
    cursor: pointer;
}

.descripcion-marca-black{
    height: 100%;
    overflow: hidden;
}
.marc1-black{
    background-image: url("../imgcomponents/producto1.png");
    background-size: cover;
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-areas:"a b";
    position: relative;
    padding-bottom: 20px;
}
.marc1-gri1{
    grid-area: b;
}

.title-marc1 p{
    font-size: 70px;
    color: #c93f8f;
    font-family: charcoal-Dance, sans-serif;
    text-align: center;
    padding-top: 70px;
}
.text-marc1 p{
    padding-top: 20px;
    font-size: 25px;
    color: white;
    padding-right: 50px;
}
.text-marc2 P{
    padding-top: 20px;
    font-size: 25px;
    color: white;
    padding-right: 50px;
}
.text-marc2{
    padding-top: 50px;
}
.text-marc2 h1{
    font-size: 40px;
    font-family: charcoal-Dance, sans-serif;
    color: #c93f8f;
}
.img-conta{
    width: 100%;
    text-align: right;
}
.img-logo{
    padding-top: 100px;
    width: 300px;
    padding-right: 50px;
}
.marc2-black{
    background-image: url("../imgcomponents/producto2.png");
    background-size: cover;
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-areas:"a b";
    position: relative;
    padding-bottom: 50px;
}
.marc2-gri1{
    grid-area: a;
}
.title-marc3 p{
    font-size: 70px;
    color: #e2de1d;
    font-family: charcoal-Dance, sans-serif;
    text-align: center;
    padding-top: 50px;
}
.marc2-gri1 .text-marc1 p{
    padding-left: 50px;
    font-size: 25px;
    color: white;
}
.marc2-gri1 .text-marc2 p{
    font-size: 25px;
    color: white;
    padding-left: 50px;
}

.marc2-gri1 .text-marc2 h1{
    padding-left: 50px;
    font-size: 45px;
    font-family: charcoal-Dance, sans-serif;
    color: #e2de1d;
}

.button-infor{
    background-color: #c93f8f;
    border-radius: 10px;
    font-size: 20px;
    border: none;
    width: 200px;
    height: 50px;
    color: white;
    font-weight: bold;
    margin-top: 100px;
}
.button-infor-2{
    background-color: #e2de1d;
    border-radius: 10px;
    font-size: 20px;
    border: none;
    width: 200px;
    height: 50px;
    color: white;
    font-weight: bold;
    margin-top: 50px;
}
.button-infor-2:hover{
    cursor: pointer;
}

.button-infor:hover{
    cursor: pointer;
}
.marc2-gri1, .marc1-gri1{
    text-align: center;
}

@media only screen and (max-width: 900px) {
    .grid-brand{
        grid-template-areas:
        "a a"
        "b b";
    }
    .marc1-black{
       background-position-x: 40%;
       grid-template-columns: 20% 80%;
    }
}