.footer-content{
    width: 100%;
    height: 250px;
    background-color: rgba(0, 0, 0, 0.89);
    overflow: hidden;
}
.sections-footer{
    width: 70%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-areas:"a b c";
}
.footer1{
    grid-area: a;
}
.footer1 img{
    margin-top: 30px;
    width: 55%;
}

.footer2{
    grid-area: b;
}
.footer2 .contac{
    text-align: center;
    font-size: 30px;
    color: white;
    padding-top: 30px;
    padding-bottom: 10px;
    font-family: charcoal-Dance, sans-serif;
}
.footer2 .text-dir{
    color: white;
    font-size: 20px;
    padding: 10px;
    text-align: center;
}
.footer3{
    grid-area: c;
    margin: 0 auto;
}
.footer3 .title-foot{
    color: white;
    font-size: 30px;
    padding: 10px;
    padding-top: 30px;
    text-align: center;
    font-family: charcoal-Dance, sans-serif;
    padding-bottom: 20px;
}
.Instagram-red{
  background: linear-gradient(45deg, #405DE6, #5851DB, #833AB4, #C13584, #E1306C, #FD1D1D, #F56040, #FFB700); 
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
}
.Instagram-red a{
    text-decoration: none;
    color: white;
}
.whatsapp-red{
    background-color: rgb(37, 203, 25);
    margin: 0 auto;
    text-align: left;
    padding-top: 5px;
    padding-left: 5px;
    position: relative;
    border-radius: 10px;
  }
  .whatsapp-red a:hover{
    cursor: pointer;
  }
  
.redes-sociales{
    color: white;
    text-decoration: none;
  }
  .redes-sociales svg{
    font-size: 40px;
  }
  .redes-sociales-sp{
    font-size: 18px;
    color: white;
    position: absolute;
    padding-top: 10px;
    padding-left: 10px;
    font-weight: bold;
  }